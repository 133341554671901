import {combineReducers} from 'redux'
import authReducer from "./auth"
import eventReducer from "./event"
import toastReducer from "./toaster"

export default combineReducers({
    authReducer,
    eventReducer,
    toastReducer
})
