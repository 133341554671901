import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Loader from "../Loader/Loader";

const ProtectedRoute = ({ component: Component, exact, path, ...rest }) => {
  const { keycloak, initialized } = useKeycloak();
  const { authenticated } = keycloak;

  if (!initialized) {
    return <Loader />;
  }

  if (!authenticated) {
    return <Redirect to="/login" />;
  }

  if (!keycloak.hasRealmRole("superadmin")) {
    keycloak.logout();
    return <Redirect to="/login" />;
  }

  return (
    <Route exact={exact} path={path}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...rest} />
    </Route>
  );
};

export default ProtectedRoute;
