import Keycloak from "keycloak-js";
import { config } from "./config";

const keycloak = new Keycloak({
  url: config.keycloakAuthUrl,
  realm: config.keycloakRealm,
  clientId: config.keycloakClient,
});

export default keycloak;
