import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import NiceModal from "@ebay/nice-modal-react";
import Loader from "./components/Loader/Loader";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import "./assets/scss/style.scss";
import { SettingsProvider } from "./context/SettingsContext";

// Routes
const Embed = lazy(() => import("./iframe/AudioPlayer"));
const Landing = lazy(() => import("./views/Landing/Landing"));
const Login = lazy(() => import("./views/Auth/Login/Login"));

const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));
const Companies = lazy(() => import("./views/Companies/Companies"));
const CreateCompany = lazy(
  () => import("./views/CompanyProfile/CompanyCreate/CompanyCreate")
);
const CompanyProfile = lazy(
  () => import("./views/CompanyProfile/CompanyUpdate/CompanyUpdate")
);

const CompanyEvents = lazy(() => import("./views/CompanyEvents/CompanyEvents"));
const CompanyTeam = lazy(() => import("./views/CompanyTeam/CompanyTeam"));
const CompanyBilling = lazy(
  () => import("./views/CompanyBilling/CompanyBilling")
);
const CompanyRecommendedKeywords = lazy(
  () => import("./views/CompanyRecommendedKeywords/CompanyRecommendedKeywords")
);
const QueuedJobs = lazy(() => import("./views/QueuedJobs/QueuedJobs"));
// const CompanyAnalytics = lazy(
//   () => import("./views/CompanyAnalytics/CompanyAnalytics")
// );
// const CompanyEventAnalytics = lazy(
//   () => import("./views/CompanyAnalytics/CompanyEventAnalytics")
// );
const CompanySlideHistory = lazy(
  () => import("./views/CompanySlideHistory/CompanySlideHistory")
);
const CompanySlideHistoryV2 = lazy(
  () => import("./views/CompanySlideHistoryV2/CompanySlideHistoryV2")
);
const CreateCompanyEvent = lazy(
  () => import("./views/CompanyEvent/CreateEvent/CreateCompanyEvent")
);
const UpdateCompanyEvent = lazy(
  () => import("./views/CompanyEvent/UpdateEvent/UpdateCompanyEvent")
);
const CompanyRequestsExisting = lazy(
  () => import("./views/CompanyRequests/ExistingCompanies/ExistingCompanies")
);
const WebPlayer = lazy(() => import("./views/WebPlayer/WebPlayer"));
const WebPlayerStatistics = lazy(
  () => import("./views/WebPlayer/WebPlayerStatistics/WebPlayerStatistics")
);
const WebPlayerStatisticsEvent = lazy(
  () =>
    import(
      "./views/WebPlayer/WebPlayerStatistics/views/WebPlayerStatisticsEvent/WebPlayerStatisticsEvent"
    )
);

const CompanyRequestsNew = lazy(
  () => import("./views/CompanyRequests/NewCompanies/CompanyRequests")
);
const CompanyRequest = lazy(
  () => import("./views/CompanyRequest/NewCompany/NewCompany")
);
const CompanyRequestExisting = lazy(
  () => import("./views/CompanyRequest/ExistingCompany/ExistingCompany")
);
const Users = lazy(() => import("./views/Users/Users"));
const CreateUser = lazy(() => import("./views/CreateUser/CreateUser"));

const QuartrMobile = lazy(
  () => import("./views/QuartrMobile/views/Explore/index")
);
const CategoryView = lazy(
  () => import("./views/QuartrMobile/views/Category/index")
);
const Countries = lazy(
  () => import("./views/QuartrMobile/views/Countries/Countries")
);
const AppReleaseIndex = lazy(
  () => import("./views/QuartrMobile/views/AppRelease/AppReleaseIndex")
);
const AppReleaseCreate = lazy(
  () =>
    import(
      "./views/QuartrMobile/components/AppRelease/AppReleaseCreate/AppReleaseCreate"
    )
);
const AppReleaseUpdate = lazy(
  () =>
    import(
      "./views/QuartrMobile/components/AppRelease/AppReleaseUpdate/AppReleaseUpdate"
    )
);
const Onboarding = lazy(
  () => import("./views/QuartrMobile/views/Onboarding/Onboarding")
);

const SpeakerImage = lazy(
  () => import("./views/QuartrMobile/views/SpeakerImage/SpeakerImage")
);

const DocumentView = lazy(() => import("./views/Document/Document"));

const SpeakerRole = lazy(() => import("./views/SpeakerRole/SpeakerRole"));

const Page404 = lazy(() => import("./views/Page404/Page404"));
const Test = lazy(() => import("./views/Page404/Test"));
const Segment = lazy(() => import("./views/Companies/Segment/Segment"));

const Customers = lazy(() => import("./views/Customers/Customers"));
const AddCustomer = lazy(() => import("./views/Customers/AddCustomer"));
const EditCustomer = lazy(() => import("./views/Customers/EditCustomer"));
const ShowCustomer = lazy(() => import("./views/Customers/ShowCustomer"));
const AddSubscription = lazy(() => import("./views/Customers/AddSubscription"));
const AddSubscriptionsFromCsv = lazy(
  () => import("./views/Customers/AddSubscriptionsFromCsv")
);
const EditSubscription = lazy(
  () => import("./views/Customers/EditSubscription")
);
const Experimental = lazy(() => import("./views/Experimental/Experimental"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <div className="App h-screen">
      <SettingsProvider>
        <QueryClientProvider client={queryClient}>
          <NiceModal.Provider>
            <Suspense fallback={<Loader className="mt-6 text-orange-900" />}>
              <Router>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/login" component={Login} />
                  <ProtectedRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies"
                    component={Companies}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/segment"
                    component={Segment}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/create"
                    component={CreateCompany}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/team"
                    component={CompanyTeam}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/billing"
                    component={CompanyBilling}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/recommended-keywords"
                    component={CompanyRecommendedKeywords}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/queued-jobs"
                    component={QueuedJobs}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/experimental"
                    component={Experimental}
                  />
                  {/* <ProtectedRoute */}
                  {/*  exact */}
                  {/*  path="/dashboard/companies/:companyId/analytics" */}
                  {/*  component={CompanyAnalytics} */}
                  {/* /> */}
                  {/* <ProtectedRoute */}
                  {/*  exact */}
                  {/*  path="/dashboard/companies/:companyId/analytics/events/:eventId" */}
                  {/*  component={CompanyEventAnalytics} */}
                  {/* /> */}
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/history"
                    component={CompanySlideHistory}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/historyV2"
                    component={CompanySlideHistoryV2}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/profile"
                    component={CompanyProfile}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/events"
                    component={CompanyEvents}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/events/create"
                    component={CreateCompanyEvent}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/events/update/:eventId"
                    component={UpdateCompanyEvent}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/events/update/:eventId/documents/:documentId"
                    component={DocumentView}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/web-player"
                    component={WebPlayer}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/statistics"
                    component={WebPlayerStatistics}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/companies/:companyId/statistics/:id"
                    component={WebPlayerStatisticsEvent}
                  />
                  <ProtectedRoute
                    exact
                    path="/embed/company"
                    component={Embed}
                  />
                  <ProtectedRoute exact path="/embed" component={Embed} />
                  <ProtectedRoute
                    exact
                    path="/dashboard/company-requests/existing"
                    component={CompanyRequestsExisting}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/company-requests"
                    component={CompanyRequestsNew}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/company-requests/existing/:id"
                    component={CompanyRequestExisting}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/company-requests/:id"
                    component={CompanyRequest}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/users"
                    component={Users}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/users/create"
                    component={CreateUser}
                  />

                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile"
                    component={QuartrMobile}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/section/:sectionId/categories/:id"
                    component={CategoryView}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/countries"
                    component={Countries}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/app-release"
                    component={AppReleaseIndex}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/app-release/create"
                    component={AppReleaseCreate}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/app-release/:id/"
                    component={AppReleaseUpdate}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/onboarding"
                    component={Onboarding}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/quartr-mobile/speaker-images"
                    component={SpeakerImage}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/transcript-speaker-roles"
                    component={SpeakerRole}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers"
                    component={Customers}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers/create"
                    component={AddCustomer}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers/:customerId"
                    component={ShowCustomer}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers/:customerId/edit"
                    component={EditCustomer}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers/:customerId/add-subscription"
                    component={AddSubscription}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers/:customerId/add-subscriptions-from-csv"
                    component={AddSubscriptionsFromCsv}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/customers/:customerId/subscriptions/:subscriptionId"
                    component={EditSubscription}
                  />
                  <Route exact path="/test" component={Test} />
                  <Route component={Page404} />
                </Switch>
              </Router>
            </Suspense>
          </NiceModal.Provider>
        </QueryClientProvider>
      </SettingsProvider>
    </div>
  );
};

export default App;
