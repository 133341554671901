const ADD = 'ADD'
const REMOVE = 'REMOVE'

const initialState = {
    toasters: []
}

const toastReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ADD:
            return {
                ...state,
                toasters: [...state.toasters, payload]
            }

        case REMOVE:
            return {
                ...state,
                toasters: state.toasters.filter(toast => toast.id !== payload)
            }

        default:
            return state
    }
}

export default toastReducer
