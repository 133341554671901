import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
// import {autoLogin} from "./actions/auth"
import { composeWithDevTools } from 'redux-devtools-extension';
import environmentHelper from '../helpers/environment';

const middlewares = environmentHelper.isProduction() ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk));

const store = createStore(rootReducer, middlewares)

// store.dispatch(autoLogin()).then(res => {
// }).catch(err => {
// })

export default store
