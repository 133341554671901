import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import SegmentScript from "./analytics/segment/script";
import keycloak from "./keycloak";
import "./analytics/sentry";

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
        responseMode: "query",
      }}
      autoRefreshToken
    >
      <Provider store={store}>
        <SegmentScript />
        <App />
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
