const appUrl = "https://admin-dev.quartr.com";

export const config = {
  environment: "DEVELOPMENT",
  appUrl,
  keycloakAuthUrl: "https://auth.data.dev.quartr.cloud",
  backendUrl: "https://api.data.dev.quartr.cloud",
  schedulerUrl: "https://scheduler.data.dev.quartr.cloud",
  embedUrl: "https://dev-app.quartr.com",
  emailRedirect: `${appUrl}/dashboard`,
  passwordResetEmailRedirect: `${appUrl}/dashboard`,
  publicWebUrl: "https://quartr.com",
  documentDataS3Url:
    "https://quartr-functions-development-document-data.s3.eu-north-1.amazonaws.com",
  documentsBaseUrl:
    "https://quartr-development-data-files.s3.eu-north-1.amazonaws.com",
  firebaseConfig: {
    apiKey: "AIzaSyAL_kuTrIplKcK_p8EQWoRz0IeCADPFf-s",
    authDomain: "quartr-development.firebaseapp.com",
    projectId: "quartr-development",
    storageBucket: "quartr-development.appspot.com",
    messagingSenderId: "59153041200",
    appId: "1:59153041200:web:6d7702ec5c91b0a8d1aaf5",
  },
  algolia: {
    companyIndex: "dev_companies",
  },
  authorizeEmbed: "56abad22b6a8498f8b78eb2919d4db2e",
  analyticStartDate: "2021-06-01",
  keycloakClient: "superadmin-dev",
  keycloakRealm: "dev",
  typesenseHost: "6empf9c783i5xkolp-1.a1.typesense.net",
  typesenseKey: "BXlFdy9VEjcNIAavLzCMBswcJ71UYy1E", // dev companies read only key. Not secret
  sentry: {
    dsn: "https://e50832612f84baaa918badd9f248a69b@o4504162200977408.ingest.us.sentry.io/4507491279241216",
    enabled: false,
    debug: true,
  },
};

export default config;
