import { LOGIN, LOGOUT, REGISTER, SET_CURRENT_COMPANY } from "../types/index";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  company: JSON.parse(localStorage.getItem("company")) || null,
  isLoggedIn: false,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER:
      return {
        ...state,
        user: payload.user,
        company: payload.company,
        isLoggedIn: true,
      };

    case SET_CURRENT_COMPANY:
      return {
        ...state,
        company: payload,
      };

    case LOGIN:
      return {
        ...state,
        user: payload.user,
        company: payload.company,
        isLoggedIn: true,
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
        company: null,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};

export default authReducer;
