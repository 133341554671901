import React from "react";
import { ImSpinner2 } from "react-icons/im";

interface Props {
  className?: string;
  fullscreen?: boolean;
  colorClassName?: string;
}
const Loader: React.FC<Props> = ({
  className,
  fullscreen,
  colorClassName = "text-orange-900",
}) => {
  return (
    <div
      className={`${className} ${
        fullscreen && "w-screen h-screen"
      } flex justify-center items-center text-center ${colorClassName}`}
    >
      <ImSpinner2 data-testid="loader" className="animate-spin h-5 w-5 mr-3" />
    </div>
  );
};

export default Loader;
