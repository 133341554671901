import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { calculateDateRangeValues } from "../helpers/date";

type DateRange =
  | "all-time"
  | "last-month"
  | "last-week"
  | "last-90-days"
  | "last-12-months"
  | "today"
  | "custom";

export interface ContextProps {
  startDate?: string;
  endDate?: string;
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  setStartDate: (startDate?: string) => void;
  setEndDate: (endDate?: string) => void;
}

const initialProps = {
  dateRange: "all-time",
};

// @ts-expect-error This shouldn't occur
const SettingsContext = createContext<ContextProps>(initialProps);

const SettingsProvider: React.FC = ({ children }) => {
  const [dateRange, setDateRange] = useState<DateRange>("all-time");
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();

  useEffect(() => {
    if (dateRange !== "custom") {
      const { rangeStart, rangeEnd } = calculateDateRangeValues(dateRange);

      setStartDate(rangeStart);
      setEndDate(rangeEnd);
    }
  }, [dateRange]);

  const contextValue = useMemo(
    () => ({
      setEndDate,
      setStartDate,
      setDateRange,
      dateRange,
      startDate,
      endDate,
    }),
    [startDate, endDate, dateRange, setDateRange, setStartDate, setEndDate]
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error(
      `SettingsContext can't be used outside of a SettingsProvider`
    );
  }

  return context;
};

export { SettingsProvider, useSettings };
