import {GET_EVENTS, SET_CURRENT_EVENT, UPDATE_EVENT} from '../types/index'
import * as events from "events";

const initialState = {
    events: [],
    currentId: null
}

const eventReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case GET_EVENTS:
            return {
                ...state,
                events: payload,
            }

        case UPDATE_EVENT:
            return {
                ...state,
                events: state.events.map(event => {
                    if (event.id === payload.id)
                        return payload

                    return event
                })
            }

        case SET_CURRENT_EVENT:
            return {
                ...state,
                currentId: payload,
            }

        default:
            return state
    }
}

export default eventReducer
