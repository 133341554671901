import config from '../config';

export const Environments = {
  Local: 'LOCAL',
  Development: 'DEVELOPMENT',
  Production: 'PRODUCTION',
};

const get = () => config.environment;

const getLog = () => `Current Environment: ${get()}`;

const isLocal = () => get() === Environments.Local;

const isDevelopment = () => get() === Environments.Development;

const isProduction = () => get() === Environments.Production;

export default {
  get,
  getLog,
  isLocal,
  isDevelopment,
  isProduction,
};
