import moment from "moment";
import config from "../config";

export const isDateValid = (date) =>
  new Date(date) != "Invalid Date" && !isNaN(new Date(date));

export const calculateDateRangeValues = (val) => {
  let rangeStart;
  let rangeEnd;

  switch (val) {
    case "all-time":
      rangeStart = moment(config.analyticStartDate).format("YYYY-MM-DD");
      rangeEnd = moment().format("YYYY-MM-DD");
      break;
    case "this-month":
      rangeStart = moment().startOf("month").format("YYYY-MM-DD");
      rangeEnd = moment().endOf("month").format("YYYY-MM-DD");
      break;
    case "last-month":
      rangeStart = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      rangeEnd = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      break;
    case "last-90-days":
      rangeStart = moment().subtract(89, "days").format("YYYY-MM-DD");
      rangeEnd = moment().format("YYYY-MM-DD");
      break;
    case "last-12-months":
      rangeStart = moment().subtract(12, "months").format("YYYY-MM-DD");
      rangeEnd = moment().format("YYYY-MM-DD");
      break;
    case "last-week":
      rangeStart = moment()
        .startOf("week")
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      rangeEnd = moment()
        .endOf("week")
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      break;
    case "custom":
      rangeStart = undefined;
      rangeEnd = undefined;
      break;
    default:
      rangeStart = moment().subtract(1, "days").format("YYYY-MM-DD");
      rangeEnd = moment().subtract(1, "days").format("YYYY-MM-DD");
  }

  return {
    rangeStart,
    rangeEnd,
  };
};

export const dateRangeOptions = [
  {
    name: "All time",
    code: "all-time",
  },
  {
    name: "This month",
    code: "this-month",
  },
  {
    name: "Last month",
    code: "last-month",
  },
  {
    name: "Last week",
    code: "last-week",
  },
  {
    name: "Last 90 days",
    code: "last-90-days",
  },
  {
    name: "Last 12 months",
    code: "last-12-months",
  },
  {
    name: "Today",
    code: "today",
  },
  {
    name: "Custom",
    code: "custom",
  },
];
