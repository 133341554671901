import * as Sentry from "@sentry/react";
import { config } from "../../config";

const isDev = config.environment !== "PRODUCTION";

Sentry.init({
  dsn: config.sentry.dsn,
  enabled: config.sentry.enabled,
  environment: config.environment,
  tracesSampleRate: isDev ? 1 : 0.1,
  profilesSampleRate: isDev ? 1 : 0.1,
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  debug: isDev,
});
